import { BaseComponent } from "@abstract/BaseComponent";
import { ChangeDetectorRef, Component } from "@angular/core";
import { NzGridModule } from "ng-zorro-antd/grid";
import { RouteDetail } from "./base/route-detail";
import { NgFor, NgIf, NgTemplateOutlet } from "@angular/common";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { TaskService } from "./services/task.service";
import { UIHelper } from "@services/UIHelper";
import { Log } from "@services/log";
import { environment } from "@env/environment";
import { Const } from "@const/Const";
import { lastValueFrom } from "rxjs";
import { TaskComponentsVisibiltyService } from "./services/task-components-visibility.service";
import { DriverChatBoxComponent } from "./base/driver-chat-box";
import RouteEntity from "./entity/RouteEntity";
import { RouteService } from "./services/route.service";
import { NoteBoxComponent } from "./base/note-box";
import { TaskSkipComponent } from "./skip-task";

// Tasks
import { TaskArrivalConfirmation } from "./confirm-arrival";
import { TaskDepartureConfirmation } from "./confirm-departure";
import { TaskRouteConfirmation } from "./confirm-route";
import { CompleteTaskServiceManager } from "./services/complete-task-service/completeTaskManager.service";
import { TaskETAConfirmation } from "./confirm-eta";
import { TaskOTPConfirmation } from "./confirm-otp";

const MEDIA_BREAKPOINT_DOWN_MD_WIDTH = 1199;

const CustomInputs = [
  NgIf, NgFor, NgTemplateOutlet,
  NzGridModule,
  NzButtonModule,
  NzIconModule,
  NzToolTipModule,
  NzAffixModule,

  RouteDetail,
  NoteBoxComponent,
  DriverChatBoxComponent,
  TaskArrivalConfirmation,
  TaskDepartureConfirmation,
  TaskRouteConfirmation,
  TaskOTPConfirmation,
  TaskETAConfirmation,
]
@Component({
  selector: '[task-queue]',
  standalone: true,
  imports: [CustomInputs],
  providers: [TaskService, RouteService, TaskComponentsVisibiltyService, CompleteTaskServiceManager],
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})

export class TaskQueueComponent extends BaseComponent {
  public shouldShowRouteDetail = false;
  public canCompleteTask = false;
  public isHasTask = false;     // Nếu chưa có task thì hiện màn get task
  public isTaskQueueEmpty = false; // Task queue rỗng
  public onProgress = false;
  public isLoading = false;
  public shouldShowMessageBox = true;
  public shouldShowNoteBox = true;
  public taskType;
  public route: RouteEntity;

  constructor(
    private changeDetectorRefs: ChangeDetectorRef,
    private visibilityService: TaskComponentsVisibiltyService,
    private taskService: TaskService,
    private routeService: RouteService,
    private completeTaskServiceManager: CompleteTaskServiceManager) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscription.add(this.taskService.message.subscribe((data) => {
      if (data.type === 'error') {
        UIHelper.showErr(data.message);
      }
    }))
    this.subscription.add(this.visibilityService.shouldShowRouteDetail.subscribe((value) => {
      this.shouldShowRouteDetail = value;
    }))
    this.fetchNewTask();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  async fetchNewTask() {
    this.isLoading = true;
    await this.taskService.fetchNewTask();
    let task = this.taskService.getTask();
    if (task) {
      this.isHasTask = true;
      this.taskType = task.type;
      this.route = this.taskService.getRouteEntity();
    }
    else {
      this.isHasTask = false;
      this.taskType = null;
    }

    this.isLoading = false;
    // Nếu fetch mới mà không có task thì là queue rỗng
    if (!this.isHasTask) {
      this.isTaskQueueEmpty = true;
    }
  }

  get isTaskArrivalConfirmation() {
    return this.taskType === Const.TrackingTask.arrivalConfirmation;
  }

  get isTaskDepartureConfirmation() {
    return this.taskType === Const.TrackingTask.departureConfirmation;
  }

  get isTaskRouteConfirmation() {
    return this.taskType === Const.TrackingTask.routeConfirmation;
  }

  get isTaskOTPConfirmation() {
    return this.taskType === Const.TrackingTask.otpConfirmation;
  }

  get isTaskETAConfirmation() {
    return this.taskType === Const.TrackingTask.etaConfirmation;
  }

  get jobId() {
    return this.route.getId();
  }
  get driverInfo() {
    return this.route.getDriver();
  }

  getUserId() {
    return this.authUser.id;
  }

  onchangeRouteDetailVisibility() {
    this.visibilityService.changeRouteDetailVisibility();
  }

  onAnswerCompleted() {
    this.canCompleteTask = true;
  }

  reset() {
    this.isHasTask = false;
    this.canCompleteTask = false;
    this.isTaskQueueEmpty = false;
    this.taskType = null;
  }

  private async updateTaskInfo() {
    const params = this.taskService.getParamsToCompleteTask();
    const answerData = params.answer;
    const type = answerData.type;
    const data = answerData.data;
    if(type === 'driverNotArrived') {
      this.updateETA({ etaTime: { to: data.eta } });
    }
    if(type === 'driverOnSite') {
      this.updateTaskStatus({
        status: 'arrived',
        changeStatusWhen: data.ata
      });
    }
    if(type === 'driverHasLeft') {
      this.updateTaskStatus({
        status: 'succeeded',
        changeStatusWhen: data.ata
      });
    }
    if(type === 'driverNotFinishedLoading' || type === 'driverNotFinishedUnloading') {

    }
    if(type === 'driverDeparted') {
      this.updateTaskStatus({
        status: 'succeeded',
        changeStatusWhen: data.departureTime
      });
    }
  }

  private async updateTaskStatus(data: any) {
    let url = `${environment.backendUrl}/${Const.APIV2(`${Const.APIURI_TASKS}/batchUpdate`)}`;
    const taskIds = this.taskService.getCurrentStopEntity().getRouteTaskIds();
    const params = {
      action: 'updateStatus',
      data: taskIds.map(taskId => ({
        id: taskId,
        data: data
      }))
    }
    try {
      await lastValueFrom(this.api.POST(url, params));
    }
    catch (e) {
      UIHelper.showErr(e.message);
    }
  }

  private async updateETA(data: any) {
    const jobId = this.routeService.getRoute()?.getId();
    const url = `${environment.backendUrl}/${Const.APIV2(`${Const.APIURI_JOBS}/${jobId}/eta-time`)}`;
    const params = this.convertData(data)
    try {
      await lastValueFrom(this.api.PUT(url, params));
    }
    catch (e) {
      UIHelper.showErr(e.message);
    }
  }

  private convertData(data: any) {
    const stop = this.taskService.getCurrentStopEntity();
    const tasks = stop.getRouteTasks() || [];
    const taskIds = tasks.map(it => it.getId());
    const shipmentIds = stop.getShipments().map(sh => sh.getId()) || [];
    const deliveryIds = stop.getShipmentDeliveryInfo().map(item => item.id) || [];
    const locationType = stop.getType();
    let params = { ...data, taskIds, shipmentIds, deliveryIds, locationType }
    return params;
  }

  async onBtnCompleteTask() {
    if (this.shouldRegenerateCurrentTaskImmediately) {
      this.regenerateCurrentTaskImmediately();
      return;
    }

    this.onProgress = true;
    const params = this.taskService.getParamsToCompleteTask();
    let taskId = this.taskService.getTaskId();
    if (!taskId) {
      return UIHelper.showErr('Invalid taskId');
    }
    await this.updateTaskInfo();
    let moreDataComplete = {
      jobId: this.jobId,
      answer: params.answer
    }
    // Xử lý thêm khi complete task
    await this.completeTaskServiceManager.getService(this.taskType)?.completeTask(moreDataComplete);
    let url = `${environment.backendWorkQueueUrl}/${Const.API_COMPLETE_TASK(taskId)}`;
    this.api.POST(url, params).subscribe({
      next: (resp) => {
        this.showCompleteTaskDialog();
        UIHelper.showSuccess('Task completed');
        this.onProgress = false;
      },
      error: (err) => {
        this.onProgress = false;
        UIHelper.showErr(`Failed to complete task ${taskId}`);
      }
    })
  }

  async onBtnSkipTask() {
    UIHelper.open<TaskSkipComponent>(TaskSkipComponent, {
      nzCancelText: 'Cancel',
      nzOkText: 'Skip task',
      nzClosable: false,
      nzMaskClosable: false,
      nzCentered: true,
      nzOnOk: async (component) => {
        component.loading = true;
        const reason = component.getReason();
        const taskId = this.taskService.getTaskId()
        let url = `${environment.backendWorkQueueUrl}/${Const.API_PAUSE_TASK(taskId)}`;
        return new Promise((resolve, reject) => {
          this.api.POST(url, { reason }).subscribe({
            next: (resp) => {
              UIHelper.showSuccess(`Task ${taskId} skipped, reason: ${reason}`, 'Task skipped');
              this.reset()
              this.fetchNewTask();
              resolve();
            },
            error: (err) => {
              UIHelper.showErr(`Failed to skip task ${taskId}`);
            }
          })
        });
      }
    });
  }

  showCompleteTaskDialog() {
    let message = "Would you like to get the next task now or wait for a moment?";
    UIHelper.confirm(message, () => {
      Log.d("Complete Task");
      this.reset()
      this.fetchNewTask();
    }, {
      title: 'Task Completed',
      okText: 'Next Task',
      className: 'modal-complete-task',
      cancelDisabled: true
    })
  }

  // Nếu cần tạo lại task này luôn bằng log hiện tại thì chỉ xử lý bằng UI
  // Không gọi xuống Backend tránh task assign không đúng người
  get shouldRegenerateCurrentTaskImmediately() {
    const params = this.taskService.getParamsToCompleteTask();
    // Dùng khi bắt buộc cần complete task
    if (params?.answer?.data?.shouldCompleteTask) return false;
    if (this.isContactDriverFailedCurrently) return true;
    return false;
  }

  // Nếu contact driver failed -> tạo task contact Carrier trên UI (không xử lý BE)
  get isContactDriverFailedCurrently() {
    const params = this.taskService.getParamsToCompleteTask();
    if (params?.answer?.type === 'contactFailed') {
      let histories = this.taskService.getNewLogHistory();
      let actions = histories?.map(it => [...(it.actions || [])])?.flat() || [];
      for (let action of actions) {
        if (action === 'markContactDriverFailed') {
          return true;
        }
        else if (action === 'markContactCarrierFailed') {
          return false;
        }
      }
    }
    return false;
  }

  async regenerateCurrentTaskImmediately() {
    this.isLoading = true;
    this.isHasTask = false;
    await this.taskService.regenerateCurrentTaskImmediately();
    if (this.taskService.getTask()) {
      this.isHasTask = true;
    }
    this.changeDetectorRefs.detectChanges()     // re-generate component
    this.isLoading = false;
  }

  onResize(event) {
    if (event.target.innerWidth <= MEDIA_BREAKPOINT_DOWN_MD_WIDTH && this.shouldShowRouteDetail) {
      // Nếu with nhỏ mà route detail đang hiện thì ẩn đi
      this.visibilityService.changeRouteDetailVisibility();
    } else if (event.target.innerWidth > MEDIA_BREAKPOINT_DOWN_MD_WIDTH && !this.shouldShowRouteDetail) {
      this.visibilityService.changeRouteDetailVisibility();
    }
  }

  autoCompleteTask() {
    this.onProgress = true;
    let taskId = this.taskService.getTaskId();
    if (!taskId) {
      return UIHelper.showErr('Invalid taskId');
    }
    let url = `${environment.backendWorkQueueUrl}/${Const.API_AUTO_COMPLETE_TASK(taskId)}`;
    this.api.POST(url, {}).subscribe({
      next: (resp) => {
        this.onProgress = false;
        this.reset()
        this.fetchNewTask();
      },
      error: (err) => {
        this.onProgress = false;
        UIHelper.showErr(err);
      }
    })

  }

}
