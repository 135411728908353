import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { CommonModule } from "@angular/common";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzStepsModule } from "ng-zorro-antd/steps";
import { NzButtonModule } from "ng-zorro-antd/button";
import { BaseComponent } from "@abstract/BaseComponent";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { FormsModule } from '@angular/forms';

const CustomInputs = [
  CommonModule,
  NzIconModule,
  NzStepsModule,
  NzButtonModule,
  NzInputModule,
  NzTypographyModule,
  FormsModule
]

@Component({
  selector: 'task-skip-component',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})

export class TaskSkipComponent extends BaseComponent {
  loading: boolean = false;

  protected inputReason = "";
  constructor() {
    super();
  }

  getReason() {
    return this.inputReason;
  }
}
