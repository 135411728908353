import { BaseComponent } from "@abstract/BaseComponent";
import { Directive, EventEmitter, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";


@Directive()
export class BaseTaskComponent extends BaseComponent {
  @Output() answerCompleted: EventEmitter<any> = new EventEmitter<any>();
  @Output() autoCompleteTask: EventEmitter<any> = new EventEmitter<any>();

  constructor(protected activatedRoute: ActivatedRoute = null) {
    super(activatedRoute);
  }

  protected onBtnRefresh() {
  }

  protected onStepAnswerCompleted() {
    let canCompleteTask = this.validateAllAnswerBeforeComplete();
    if (canCompleteTask) {
      this.onAllAnswerCompleted();
    }
  }

  // Khi hoàn thành hết các step thì mới bắn data để complete task
  protected onAllAnswerCompleted() {
    this.answerCompleted.emit();
  }

  // Kiểm tra đã hoàn thành hết các step chưa
  // Override lại hàm này tại mỗi task
  protected validateAllAnswerBeforeComplete(): boolean {
    return true;
  }

  // Task vẫn còn nhưng thực tế đã complete bằng cách khác
  // thì gọi hàm này để complete task
  protected onAutoCompleteTask() {
    this.autoCompleteTask.emit();
  }
}