import { CommonModule, NgFor, NgIf } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { BaseFormDialog } from "@app/dialogs/base-form-dialog";
import RouteEntity from "@app/work-dispatcher/task-queue/entity/RouteEntity";
import { Const } from "@const/Const";
import { InputHelper } from "@services/input-helper";
import { Log } from "@services/log";
import { MasterData } from "@services/master.data";
import { UIHelper } from "@services/UIHelper";
import { Utils } from "@services/utils";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzSelectModule } from "ng-zorro-antd/select";
import { Subscription } from "rxjs";
import { NzAutocompleteModule, NzOptionSelectionChange } from "ng-zorro-antd/auto-complete";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NZ_MODAL_DATA } from "ng-zorro-antd/modal";
import { DialogService } from "@app/dialogs/dialog.service";
import { CreateDriverTaskRouteConfirmation } from "../create-driver";


const CustomInputs = [
  NgIf, NgFor,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  NzFormModule,
  NzInputModule,
  NzRadioModule,
  NzSelectModule,
  NzAutocompleteModule,
  NzButtonModule,
  CreateDriverTaskRouteConfirmation
]

@Component({
  selector: '[assign-driver-dialog]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})

export class AssignDriverDialog extends BaseFormDialog {
  static get driverProfileDeclaration() {
    return {
      firstName: { label: "First name", required: true },
      lastName: { label: "Last name", required: true },
      phone: {
        label: "Phone",
        inputType: "tel",
        required: true,
        getValue: InputHelper.getValuePhone,
        formatValue: InputHelper.formatPhone,
      },
    };
  }
  protected formGroupDeclaration: FormGroupDeclaration = {
    driverId: { label: "Driver", required: true },
    location: { label: "Location", type: "formGroup", initialValue: { city: null, state: null }, childItem: {
      city: { label: "City", required: false },
      state: { label: "State", required: false },
    }},
    secondaryDriver: {
      label: "",
      type: "formGroup",
      childItem: {
        driverId: { label: "Secondary Driver", required: false },
        location: { label: "Location", type: "formGroup", initialValue: { city: null, state: null }, childItem: {
          city: { label: "City", required: false },
          state: { label: "State", required: false },
        }},
      }
    }
  };

  routeEntity: RouteEntity;
  
  constructor(@Inject(NZ_MODAL_DATA) data: any) {
    super(data);
    this.routeEntity = data?.routeEntity;
  }

  private subAddressSuggestion: Subscription;
  private timerAddressSuggestion;
  public listAddressAutoComplete: any = {};
  allStates = [
    { label: 'US States', items: MasterData.getStatesUS() },
    { label: 'Canada Provinces', items: MasterData.getCanadaProvinces() },
  ];

  public methodToSelectDriver: "select" | "create";

  get isCreateNew(): boolean {
    return !this.model;
  }
  get btnSubmitLabel(): string {
    return this.isCreateNew ? "Assign" : "Change";
  }

  get btnCancelLabel(): string {
    return "Close";
  }

  get assignDriverLabel(): string {
    return this.isCreateNew ? "Assign Driver" : "Change Driver";
  }

  get canClickOK() {
    return this.needUpdate && !this.onProgress && !this.readOnly;
  }

  public onProgress = false;
  public isError = false;
  public isLoading = false;
  public listDrivers = [];
  public driverId = "";
  public carrierId = "";
  public jobId = "";
  public isRequiredTeamDriver: boolean = false;
  public readOnly: boolean = false;

  ngOnInit(): void {
    super.ngOnInit();
    this.jobId = this.routeEntity?.getId() || '';
    this.carrierId = this.routeEntity?.getCarrierId() || '';
    this.driverId = this.routeEntity?.getDriverId() || '';
    this.isRequiredTeamDriver = (this.routeEntity.getRequiredVehicle()?.options ?? []).includes('TEAM');
    this.readOnly = [Const.JobStatus.inProgress, Const.JobStatus.completed].includes(this.routeEntity.getStatus())
    if (this.jobId && this.carrierId) {
      this.loadData();
    }
    if (this.formInput && this.readOnly) {
      this.setEnableFormGroup(false);
    }
  }

  get shouldCreateFormImmediately() {
    return !this.driverId;
  }

  private setRequiredSecondaryDriver(isRequired: boolean) {
    let fg = this.formInput.get('secondaryDriver');
    for(let key of Object.keys(this.formGroupDeclaration.secondaryDriver.childItem)){
      if(key == 'location') continue;
      let fc = fg.get(key);
      if(!isRequired) {
        fc.setValidators(null);
      } else {
        fc.setValidators([Validators.required]);
      }
      fc.updateValueAndValidity();
    }
  }

  protected createFormInput(bindData?: any): void {
    super.createFormInput(bindData);
    if(this.isRequiredTeamDriver){
      this.setRequiredSecondaryDriver(true)
    }
  }

  loadData() {
    this.isLoading = true;
    let urls = [
      `${Const.APIURI_DRIVERS}?status=${Const.DriverStatus.active}&limit=-1&carrierId=${this.carrierId}`,
      
    ]
    if(this.driverId) {
      urls.push(`${Const.APIURI_JOBS}/${this.jobId}/metadata`)
    }
    this.api.concurrentGET(urls).subscribe({
      next: (resp) => {
        this.listDrivers = resp[0].data?.list_data || [];
        if (resp.length > 1) {
          this.setDataModel(resp[1]);
          this.createFormInput(this.model);
        }
        this.isLoading = false;
      },
      error: (err) => {
        UIHelper.showErr(err);
        this.isLoading = false;
      }
    });
  }

  private setDataModel(resp) {
    const assignedDriver = resp?.data?.assignedDriver || {};
    const assignedSecondaryDrivers = resp?.data?.assignedSecondaryDrivers || {};
    this.model = {
      ...assignedDriver,
      location: {
        city: assignedDriver?.currentLocation?.city || null,
        state: assignedDriver?.currentLocation?.state || null,
      },
      secondaryDriver: {
        driverId: assignedSecondaryDrivers?.[0]?.driverId || null,
        location: {
          city: assignedSecondaryDrivers?.[0]?.currentLocation?.city || null,
          state: assignedSecondaryDrivers?.[0]?.currentLocation?.state || null,
        }
      }
    }
  }

  onBtnSave(): void {
    if (!this.needUpdate) {
      return;
    }
    let json: any = this.getFormData_JSON(true);
    if(!this.isRequiredTeamDriver){
      this.assignDriver(json.driverId);
      if(json.location?.city || json.location?.state) this.updateLocation(json.location);
    } else {
      let primaryDriverId = json.driverId;
      let secondaryDriverId = json.secondaryDriver.driverId;
      if(!primaryDriverId) {
        UIHelper.showErr("Please select primary driver");
        return;
      }
      if(!secondaryDriverId) {
        UIHelper.showErr("Please select secondary driver");
        return;
      }
      if(primaryDriverId == secondaryDriverId) {
        UIHelper.showErr("Primary driver and secondary driver must be different");
        return;
      }
      this.assignTeamDriver(primaryDriverId, json.secondaryDriver);
      if(json.location?.city || json.location?.state) this.updateLocation(json.location);
    }
  }

  private assignDriver(driverId) {
    if (!this.jobId) {
      return UIHelper.showErr("jobId is required");
    }
    this.setEnableFormGroup(false);
    this.onProgress = true;
    const url = Const.APIV2(`${Const.APIURI_JOBS}/${this.jobId}/assign_driver`);
    this.api.PUT(url, { driverId }).subscribe({
      next: (resp) => {
        Log.d("assignDriver done ", resp);
        UIHelper.showSuccess("Assign driver successfully");
        this.onProgress = false;
        this.onUpdateSuccess(resp);
      },
      error: (err) => {
        UIHelper.showErr(err);
        this.onProgress = false;
      }
    });
  }

  private assignTeamDriver(primaryDriverId, secondaryDriver) {
    if (!this.jobId) {
      return Log.e("jobId is required");
    }
    const params = {
      primaryDriverId,
      secondaryDrivers: {
        driverIds: [secondaryDriver.driverId],
        locations: [
          {
            city: secondaryDriver.location.city || null,
            state: secondaryDriver.location.state || null
          }
        ]
      }
    }
    this.setEnableFormGroup(false);
    this.onProgress = true;
    const url = Const.APIV2(`${Const.APIURI_JOBS}/${this.jobId}/assign_team_driver`);
    this.api.PUT(url, params)
      .subscribe(
        (resp) => {
          Log.d("assignDriver done ", resp);
          UIHelper.showSuccess("Assign driver successfully");
          this.onUpdateSuccess(resp);
          this.onProgress = false;
        },
        (err) => {
          UIHelper.showErr(err);
          this.onProgress = false;
        }
      );
  }

  private updateLocation(location) {
    let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.jobId}/driver_location`);
    this.api.POST(url, { ...location }).subscribe({
      next: (resp) => {
        Log.d("update location driver done ", resp);
        this.updateSuccess(resp);
      }, 
      error: (err) => {
        UIHelper.showErr(err);
      }
    });
  }


  getDriverName(driver) {
    if (!driver) return '';
    return Utils.getFullName(driver);
  }

  onLocationSelected(event: NzOptionSelectionChange, location, type: 'primary'|'secondary') {
    if (!event.isUserInput) {
      return;
    }
    this.setLocation(location, type);
  }

  private setLocation(location: string, type: 'primary'|'secondary') {
    let arr = location?.trim()?.split(',') || [];
    if (arr.length == 2 && type == 'primary') {
      this.setItemValue('location.city', arr[0].trim());
      this.setItemValue('location.state', arr[1].trim());
    }
    if(arr.length == 2 && type == 'secondary') {
      this.setItemValue('secondaryDriver.location.city', arr[0].trim());
      this.setItemValue('secondaryDriver.location.state', arr[1].trim());
    }
  }

  getCityItem(item) {
    let arr = item?.trim()?.split(',') || [];
    if (arr.length == 2) {
      return arr[0]
    } else {
      return item;
    }
  }

  getStateDesc(state) {
    return Utils.getStateDesc(state);
  }

  onInputChanged(event, key) {
    switch (key) {
      case 'city':
        return this.tryAddressSuggestion(event.target);
      default:
        return super.onInputChanged(event, key);
    }
  }

  private tryAddressSuggestion(elm: HTMLInputElement) {
    clearTimeout(this.timerAddressSuggestion);
    let userInput = elm.value;
    if (!userInput) {
      this.subAddressSuggestion?.unsubscribe();
      this.listAddressAutoComplete[elm.id] = [];
      return;
    }
    this.timerAddressSuggestion = setTimeout(() => this.doAddressSuggestion(elm, userInput), 100);
  }

  private doAddressSuggestion(elm: HTMLInputElement, userInput: string) {
    this.subAddressSuggestion?.unsubscribe();
    this.subAddressSuggestion = this.api.searchUsCities(userInput).subscribe({
      next: (resp) => {
        this.listAddressAutoComplete[elm.id] = resp.data.list_data.map(it => `${it.city}, ${it.stateCode}`);
      }, 
      error: (err) => {
        Log.e('doAddressSuggestion failed. ', err);
      }
    });
  }

  public onBtnCreateDriver() {
    DialogService.openFormDialog(CreateDriverTaskRouteConfirmation, {
      nzData: {
        carrierId: this.carrierId,
        closeOnSuccess: true,
        createSuccess: resp => {
          this.listDrivers.push(resp.data);
          this.setItemValue('driverId', resp.data.id);
        }
      },
      nzClassName: 'modal-no-padding assign-driver-form',
    });
  }

  public onBtnCreateDriverSecondary() {
    DialogService.openFormDialog(CreateDriverTaskRouteConfirmation, {
      nzData: {
        carrierId: this.carrierId,
        closeOnSuccess: true,
        createSuccess: resp => {
          this.listDrivers.push(resp.data);
          this.setItemValue('secondaryDriver.driverId', resp.data.id);
        }
      },
      nzClassName: 'modal-no-padding assign-driver-form',
    });
  }

  public getDriverNameAndPhoneLabel(driver){
    const name = this.getDriverName(driver);
    const phone = InputHelper.formatPhone(driver.phone);
    if(phone)
      return `${name}, ${phone}`;
    else return name;
  }
}