import { Component } from "@angular/core";
import { BaseTaskComponent } from "../BaseTaskComponent";
import { Const } from "@const/Const";
import { CommonModule } from "@angular/common";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzStepsModule } from "ng-zorro-antd/steps";
import { TaskOverview } from "../base/task-overview";
import { LiveTrackingStatus } from "../base/live-tracking-status";
import { DriverResponseComponent } from "../base/driver-status";
import { NzButtonModule } from "ng-zorro-antd/button";
import { TaskService } from "../services/task.service";
import { LogHistoryComponent } from "../base/log-history";
import { SendTrackingLinkBtn } from "../base/send-tracking-link-btn";
import { ContactDriverComponent, ContactInfo } from "../base/contact-driver-box";
import { ActionLogHistory, DriverStatus } from "@app/interfaces";
import { DriverAnswerData } from "@app/interfaces/answer-data";
import { DispatchRouteMap } from '../../map/route-map'
import { Shipment } from "@wearewarp/types/data-model";
import StopEntity from "../entity/StopEntity";
import { DriverLocationService } from "@app/work-dispatcher/map/services/driver-location";
import { RouteService } from "../services/route.service";
import RouteEntity from "../entity/RouteEntity";
import { LocationInstructionComponent } from "../base/location-instruction";

const CustomInputs = [
  CommonModule,
  NzIconModule,
  NzStepsModule,
  NzButtonModule,
  
  TaskOverview,
  LiveTrackingStatus,
  DriverResponseComponent,
  LogHistoryComponent,
  SendTrackingLinkBtn,
  ContactDriverComponent,
  DispatchRouteMap,
  LocationInstructionComponent
]

@Component({
  selector: 'task-arrival-confirmation',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})

export class TaskArrivalConfirmation extends BaseTaskComponent{
  public stepIndex: number = 0;
  public driverStatus: DriverStatus | undefined = undefined;
  public isEnableLiveTracking: boolean = false;
  public isRemindedDriver: boolean = false;
  private route: RouteEntity;
  public routeData: any;
  public driverInfo: any;
  public carrierInfo: any;
  public logHistory: ActionLogHistory[] = [];
  public stopTimeZone;
  public shipments: Shipment[] = [];
  public currentStop: StopEntity;
  public mainContactInfo: ContactInfo;
  public locationInstruction: string = '';

  constructor(
    private taskService: TaskService, 
    private routeService: RouteService,
    private driverLocationService: DriverLocationService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isEnableLiveTracking = this.routeService.getLiveTrackingStatus();
    this.loadData();
  }

  get taskName() {
    return Const.TrackingTaskLabel.arrivalConfirmation;
  }

  get shouldRemindDriver() {
    return this.driverInfo && !this.isEnableLiveTracking && this.driverStatus !== 'contactFailed';
  }

  loadData() {
    this.route = this.routeService.getRoute();
    this.routeData = this.routeService.getRoute()?.getData();
    this.driverInfo = this.routeData?.driver;
    this.carrierInfo = this.routeData?.carrier;
    this.shipments = this.route.getShipments().map(shipment => shipment.getData());
    this.logHistory = this.taskService.getLogHistory();
    this.currentStop = this.taskService.getCurrentStopEntity();
    this.stopTimeZone = this.currentStop?.getTimeZoneStandard();
    this.mainContactInfo = this.getContactInfo();
    this.locationInstruction = this.getLocationInstruction();
  }

  public isShowMap: boolean = false;
  changeMapVisiable() {
    this.isShowMap = !this.isShowMap;
    if(this.isShowMap) this.driverLocationService.setRouteId(this.routeData.id);
  }

  getTaskFollowingTitle() {
    let title = "Follow steps below to complete this task";
    if (this.isEnableLiveTracking) {
      return "Please use Live-tracking above and log driver's status here";
    }
    return title;
  }

  getStepTitle(index) {
    switch (index) {
      case 1:
        let contactEntity = this.mainContactInfo.entity || 'Driver';
        let textAddr = this.currentStop?.getLocationName() || this.currentStop?.getAddressText() || '';
        return `Call and ask the ${contactEntity} "Have you arrived at the stop ${textAddr}?"`;
      case 2:
        return 'Remind Driver to turn on Live tracking "Can you please turn on live tracking so we can know your location."'
    }
  }

  getDriverResponseOptions(): DriverStatus[] {
    if (this.isEnableLiveTracking) {
      return ['driverOnSite', 'driverHasLeft'];
    } else {
      return ['driverNotArrived', 'driverOnSite', 'driverHasLeft', 'contactFailed'];
    }
  }

  onDriverResponseChange(resp: DriverAnswerData) {
    this.driverStatus = resp?.type;
    this.taskService.updateAnswerData(resp);
    this.onStepAnswerCompleted();
    if (this.shouldRemindDriver) {
      this.stepIndex = 1;
    }
  }

  onRemindDriverCompleted(value) {
    // Chỉ truyền value khi là action mới, để ghi lại log
    if(value && value === 'send-tracking-link') {
      let log: ActionLogHistory = {
        actions: ['sendTrackingLink'],
      }
      this.taskService.updateLogHistory(log);
    }
    else if (value && value === 'mark-driver-refused') {
      let log: ActionLogHistory = {
        actions: ['markDriverRefusedLiveTracking'],
      }
      this.taskService.updateLogHistory(log);
    }
    this.isRemindedDriver = true;
    this.onStepAnswerCompleted();
  }

  protected validateAllAnswerBeforeComplete(): boolean {
    // step 1
    if (!this.driverStatus) return false;
    // step 2
    // Tạm không bắt buộc send tracking link
    // if (this.shouldRemindDriver) {
    //   if (!this.isRemindedDriver) return false;
    // }
    return true;
  }

  getContactInfo(): ContactInfo {
    let contactInfo;
    let contactEntity: 'Driver' | 'Carrier' = 'Driver';
    let listLogActions = this.logHistory.map(log => [...(log.actions || [])]).flat() || [];

    for (let action of listLogActions) {
      if (action === 'markContactDriverFailed') {
        // Nếu contact driver failed trc đó thì gọi cho carrier
        contactEntity = 'Carrier';
        break;
      }
      else if (action === 'markContactCarrierFailed') {
        // Gọi carrier failed thì quay lại gọi cho driver
        break;
      }
    }
    if (contactEntity === 'Carrier' || !this.driverInfo) {
      contactInfo = this.carrierInfo?.basicInfo?.contacts?.[0] || this.carrierInfo;
      contactEntity = 'Carrier';
    } 
    else {
      contactInfo = this.driverInfo;
      contactEntity = 'Driver';
    }
    return { ...contactInfo, entity: contactEntity }
  }

  getLocationInstruction() {
    return this.currentStop?.getDeliveryInfo().instructions;
  }
}