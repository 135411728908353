<div class="general-info-container">
  <div class="header-box">
    <div class="route-link">Route {{ displayRouteInfos.code }} > Stop {{ displayTaskInfos.stopIndex }}</div>
    <div class="flex flex-space-between top10">
      <div class="flex">
        <h2>{{ taskTitle }}</h2>
        <div *ngIf="isTaskCompleted" class="left10"><span class="work-task-status completed">Completed</span></div>
      </div>
      <div><button nz-button nzTheme="outline" (click)="onBtnRefresh()"><i nz-icon nzType="reload" nzTheme="outline"></i>Refresh</button></div>
    </div>
  </div>
  <div class="separator h top10 bottom10"></div>
  <div class="content-box">
    <nz-row>
      <nz-col nzSpan="12">
        <div class="flex">
          <div class="route-code">Route <a (click)="onChangeRouteDetailVisibility()" style="color:#253bbb; text-decoration: none;">{{ displayRouteInfos?.code }}</a></div>
          <div class="route-status">{{ displayRouteInfos?.status}}</div>
        </div>
        <div class="top10" style="font-weight: 500;">
          {{ displayRouteInfos?.origin }}
          <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
          {{ displayRouteInfos?.destination }}
          <span class="left5">({{ getNumOfStops()}} Stops)</span>
        </div>
        <div class="top20">
          <div nz-row *ngFor="let key of ['clients', 'carrier', 'equipment', 'items']" class="bottom10" >
            <div nz-col [nzMd]="6" [nzXs]="24"><span class="label-text">{{ getTextLabel(key) }}</span></div>
            <div nz-col [nzMd]="18" [nzXs]="24">
              <ng-container [ngSwitch]="key">
                <ng-container *ngSwitchCase="'clients'">
                  <span nz-tooltip [nzTooltipTitle]="getClientsTooltipTitle()">{{ displayRouteInfos[key] }}</span>
                </ng-container>
    
                <ng-container *ngSwitchDefault>
                  <span>{{ displayRouteInfos[key] }}</span>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </nz-col>

      <nz-col nzSpan="12">
        <div class="flex">
          <div class="stop-location flex">
            <div class="stop-calendar-icon right10"><span nz-icon nzType="calendar" nzTheme="fill" style="color: #fff; font-size: 13px;"></span></div>
            {{ displayTaskInfos.locationName ? displayTaskInfos.locationName + ' - ' : '' }}Stop {{ displayTaskInfos.stopIndex }} - {{ displayTaskInfos.type }}
          </div>
          <div class="task-status">{{ displayTaskInfos?.status }}</div>
        </div>
        <div class="top10">{{ displayTaskInfos.address }}</div>
        <div class="top20">
          <div nz-row *ngFor="let key of displayTaskLabelsArr" class="bottom10" style="align-items: center;">
            <div nz-col [nzMd]="6" [nzXs]="24">
              <ng-container [ngSwitch]="key">
                <ng-container *ngSwitchCase="'liveETA'">
                  <span class="label-text">{{ getTextLabel(key) }}</span>
                  <img src="assets/svg/live_location.svg" alt="" class="left5" style="padding-bottom: 5px;">  
                </ng-container>

                <ng-container *ngSwitchDefault>
                  <span class="label-text">{{ getTextLabel(key) }}</span>
                </ng-container>
              </ng-container>
            </div>
            <div nz-col [nzMd]="18" [nzXs]="24">
              <ng-container [ngSwitch]="key">
                <ng-container *ngSwitchCase="'liveETA'">
                  <div class="top5" *ngIf="representativeTask" delivery-eta [overflow]="true" [compact]="true" [id]="'TASK_' + representativeTask?.id" [tz]="getCurrentStopTimeZone()" (getETASuccess)="onChangeLiveETA($event)"></div>
                </ng-container>

                <ng-container *ngSwitchCase="'currentState'">
                  <span class="current-state" [ngClass]="currentState">{{ displayTaskInfos[key] }}</span>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  <span>{{ displayTaskInfos[key] }}</span>
                </ng-container>
              </ng-container>
              </div>
          </div>
        </div>
      </nz-col>
    </nz-row>
  </div>
</div>