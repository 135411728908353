<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>

<div *ngIf="!isLoading && !needAutoCompleteTask">
  <task-overview [taskTitle]="taskName"></task-overview>

  <div class="body-container top10">
    <!-- Confirm items & log history -->
    <div class="flex flex-space-between top10">
      <div>
        <div class="label-text">{{ statusConfirmationText }}</div>
        <div *ngFor="let key of confirmItemsArr" class="confirm-item bottom8" [ngClass]="{'confirmed': isConfirmedItem(key), 'hidden': !shouldShowItem(key)}">
          <i *ngIf="!isConfirmedItem(key)" nz-icon nzType="question-circle" nzTheme="fill" style="font-size: 16px;"></i>
          <i *ngIf="isConfirmedItem(key)" nz-icon nzType="check-circle" nzTheme="fill" style="font-size: 16px;"></i>
          <span class="left10">{{ getItemLabel(key) }}</span>
        </div>
      </div>
      <div>
        <div log-history [logs]="logHistory" [timezone]="stopTimeZone"></div>
      </div>
    </div>

    <!-- Call carrier & answer box -->
    <div>
      <div>
        <div class="label-text top20">{{ remindCarrierLabel }}</div>
        <div class="desc-box top5">
          <span class="light-text" [innerHTML]="getRemindCarrierDescription()"></span>
        </div>  
      </div>
      <!-- contact carrier box -->
      <div contact-carrier-box [carrierId]="assignedCarrierId" class="top15"></div>

      <div class="top15">
        If carrier can't find the email, please 
        <span *ngIf="!isSendMailLoading && !timeCountdown" class="resend-email" (click)="onBtnResendBookingEmail()">resend Booking Confirmation email.</span>
        <span *ngIf="isSendMailLoading" nz-icon nzType="loading" nzTheme="outline"></span>
        <span *ngIf="timeCountdown" class="resend-email">resend after ({{ timeCountdown }}s)</span>
      </div>
      
      <!-- answers box -->
      <div class="answer-box top20">
        <div class="light-text">Select Carrier's response below</div>
        <div class="top10">
          <div carrier-answers-for-task-route-confirmation
            [routeEntity]="getRoute()"
            (responseChange)="onResponseChange($event)"
            (refresh)="refreshRouteData()"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>