<div class="note-container"
  nz-popover [nzPopoverTrigger]="'click'" [(nzPopoverVisible)]="noteBoxVisiable"
  [nzPopoverPlacement]="'topRight'"
  [nzPopoverContent]="tplNoteBox"
  (nzPopoverVisibleChange)="onVisiableChange()">
  <nz-badge [nzCount]="countNewItems" [nzOffset]="[5,-5]">
    <i class="fa fa-edit" style="font-size: 24px; color: #101014;"></i>
  </nz-badge>

  <ng-template #tplNoteBox>
  <ng-container *ngIf="!noteItems ||  noteItems.length === 0">
    <nz-empty [nzNotFoundContent]="'Empty Note'"></nz-empty>
  </ng-container>

  <div *ngIf="noteItems.length > 0" style="width: 360px;">
    <div class="header-box flex flex-space-between">
      <div style="font-size: 16px; font-weight: 600;">Note</div>
      <div><i nz-icon nzType="close" nzTheme="outline" (click)="onBtnCloseNoteBox()" style="cursor: pointer;"></i></div>
    </div>
    <div class="content-box top20 bottom10" style="min-height: 200px; max-height: 400px; overflow-y: auto; padding-right: 10px;">
      <nz-row *ngFor="let item of noteItems" [nzGutter]="[16, 40]">
        <nz-col [nzSpan]="3">
          <div class="avt" style="background-color: #e7e7e7; width: 36px; height: 36px; text-align: center; align-content: center; font-size: 16px;font-weight: 600;border-radius: 100%;">
            {{ item.avtCharacter }}
          </div>
        </nz-col>
        <nz-col [nzSpan]="21">
          <div class="flex flex-space-between" style="height: 36px; align-items: center;">
            <div style="font-weight: 600;">{{ item.collectionName }}</div>
            <div style="color:#b7b7b7">{{ item.longAgo }}</div>
          </div>
          <div class="top5"><span style="white-space: pre-line;">{{ item.content }}</span></div>
          <div class="list-img-upload top5" *ngIf="item?.imgArr">
            <div *ngFor="let imgUrl of item.imgArr">
              <img (click)="viewImageItem(imgUrl)" style="width: 180px; cursor: pointer;" [src]="imgUrl" alt="">
            </div>
          </div>
        </nz-col>
      </nz-row>
    </div>    
  </div>
</ng-template>

</div>