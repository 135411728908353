import { Component, Inject } from "@angular/core";
import { NZ_MODAL_DATA } from "ng-zorro-antd/modal";
import { ModalDataImg } from "./dialog.service";

@Component({
  selector: '[dlg-preview-img]',
  standalone: true,
  templateUrl: './dlg-preview-img.html',
  styleUrls: ['./dlg-preview-img.scss' ,'./dialog.scss']
})
export class DlgPreviewImgComponent {
  public data: ModalDataImg;

  constructor(@Inject(NZ_MODAL_DATA) data: ModalDataImg) {
    this.data = data;
  }
  ngOnInit() {
    console.log(this.data.imgUrl);
  }

}