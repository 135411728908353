<div class="answers-container">
  <nz-radio-group [(ngModel)]="carrierStatus">
    <div nz-row [nzGutter]="16">
      <div nz-col [nzSpan]="24 / optionArr.length" *ngFor="let key of optionArr">
        <div class="box-status-item"
          nz-tooltip
          [nzTooltipTrigger]="templateController[key].trigger"
          [nzTooltipTitle]="getTemplate(key)"
          [nzTooltipTitleContext]="{ $implicit: key }"
          [nzTooltipPlacement]="'top'"
          [nzTooltipColor]="'white'"
          [(nzTooltipVisible)]="templateController[key].visible"
          (click)="onTooltipClick(key)"
          nzTooltipOverlayClassName="route-confirmation-tooltip-info"
          style="cursor: pointer;"
        >
          <div class="flex flex-space-between">
            <div class="label-text">{{ getLabel(key)}}</div>
            <div class="left10"><label nz-radio [nzValue]="getValue(key)" [nzDisabled]="!shouldEnableRadio(key)"></label></div>
          </div>
          <div *ngIf="!needSpecialDesc(key)" class="text-desc top20" style="white-space: pre-line;" [innerHTML]="getDesc(key)"></div>
          <div *ngIf="needSpecialDesc(key)" class="text-desc top20">
            <div *ngIf="key === 'keepRoute'">
              If carrier doesn’t know how to confirm via email, use 
              <span class="guildline" nz-tooltip [nzTooltipTitle]="tplGuideline" [nzTooltipPlacement]="'right'" [nzTooltipColor]="'white'" nzTooltipOverlayClassName="route-confirmation-tooltip-info">this guide</span> 
              to instruct them.
            </div>
          </div>
        </div>
      </div>
    </div>
  </nz-radio-group>

  <ng-template #tplMarkKeepThisRoute let-key>
    <div *ngIf="isConfirmedLoadtender || !requireCarrierAcceptLoadtender" class="answer-tooltip-content">
      <div style="float: right;"><i nz-icon nzType="close" nzTheme="outline" (click)="onCloseTooltip(key)" style="font-size: 18px; cursor: pointer"></i></div>
      <div route-check-list (onAllItemsConfirmed)="carrierKeepThisRoute($event)" (onCompletedCheckListItem)="completedCheckListItem($event)"></div>
    </div>

    <div *ngIf="!isConfirmedLoadtender  && requireCarrierAcceptLoadtender" class="center-children">
      <div>
        When they confirmed<br/>
        => <span class="link-text" (click)="onBtnRefresh()">Refresh this page</span>
    </div>
    </div>
  </ng-template>

  <ng-template #tplMarkWaitingForConfirmation let-key>
    <div>
      <div style="display: flex; justify-content: flex-end;">
        <button nz-button nzType="primary" nzTheme="outline" [disabled]="!canUpdate(key)" (click)="updateCarrierAnswer(key)">Confirm</button>
      </div>
      <div class="flex top10">
        <div style="align-self: center;">Follow-up after</div>
        <div class="left10">
          <nz-select [(ngModel)]="tmpDelayTime" style="width:200px">
            <nz-option *ngFor="let item of delayConfirmOptions" [nzValue]="item" [nzLabel]="getDelayLabel(item)"></nz-option>
          </nz-select>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #tplMarkDeclinedRoute let-key>
    <div>
      <div style="display: flex; justify-content: flex-end;">
        <button nz-button nzType="primary" nzTheme="outline" [disabled]="!canUpdate(key)" (click)="updateCarrierAnswer(key)">Confirm</button>
      </div>
      <div class="flex center-children top10">
        <div>Reason</div>
        <div class="left10">
          <nz-select [(ngModel)]="tmpDeclinedReason" style="width: 200px; text-align: start;" 
            nzPlaceHolder="Select reason" 
            [nzDropdownMatchSelectWidth]="false"
            [nzDropdownRender]="otherReason">
            <nz-option *ngFor="let item of declinedReasonOptions" [nzValue]="item" [nzLabel]="item"></nz-option>
          </nz-select>
          <ng-template #otherReason>
            <div style="padding: 5px 12px;">
              Other: <input nz-input [(ngModel)]="tmpOtherReason" (ngModelChange)="otherDeclinedReasonInputChange($event)" placeholder="E.g family issue" style="width: 200px;" />
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #tplGuideline>
    <div class="guideline-box">
      <div class="title">Guide to confirm booking</div>
      <div class="separator h top10 bottom10"></div>
      <ol>
        <li>{{ getGuideline(1) }}</li>
        <li>{{ getGuideline(2) }}</li>
      </ol>
    </div>
  </ng-template>
</div>

