<div class="answers-container">
  <nz-radio-group [(ngModel)]="answerType">
    <div nz-row [nzGutter]="16">
      <div nz-col [nzSpan]="24 / optionArr.length" *ngFor="let key of optionArr">
        <div class="box-status-item"
          nz-tooltip
          [nzTooltipTrigger]="getTooltipTrigger(key)"
          [nzTooltipTitle]="getTemplate(key)"
          [nzTooltipTitleContext]="{ $implicit: key }"
          [nzTooltipPlacement]="'top'"
          [nzTooltipColor]="'white'"
          [(nzTooltipVisible)]="templateController[key].visible"
          nzTooltipOverlayClassName="answer-tooltip-info"
          style="cursor: pointer;"
        >
          <div class="flex flex-space-between">
            <div class="label-text">{{ getLabel(key)}}</div>
            <div><label nz-radio [nzValue]="getValue(key)" [nzDisabled]="!shouldEnableRadio(key)"></label></div>
          </div>
          <div class="text-desc top20" style="white-space: pre-line;" [innerHTML]="getDesc(key)"></div>
        </div>
      </div>
    </div>
  </nz-radio-group>

  <ng-template #tplMarkDriverOnTime let-key>
    <div class="mark-driver-ontime">
      <div style="display: flex; justify-content: flex-end;">
        <button nz-button nzType="primary" nzTheme="outline" [disabled]="!canUpdateAnswer(key)" (click)="updateAnswer(key)">Confirm</button>
      </div>
      <div *ngIf="etaOnTimeErr" class="text-error">{{etaOnTimeErr}}</div>
      <div class="flex flex-space-between top10" style="align-items: center;">
        <div>ETA</div>
        <div class="left10">
          <nz-date-picker [nzShowTime]="{ nzFormat: 'HH:mm' }" nzFormat="yyyy-MM-dd HH:mm" [(ngModel)]="tmpETA" nzShowNow="false" (ngModelChange)="validateTimeInput(key)"></nz-date-picker>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #tplMarkDriverLate let-key>
    <div class="mark-driver-late">
      <div style="display: flex; justify-content: flex-end;">
        <button nz-button nzType="primary" nzTheme="outline" [disabled]="!canUpdateAnswer(key)" (click)="updateAnswer(key)">Confirm</button>
      </div>
      <div *ngIf="etaLateErr" class="text-error">{{etaLateErr}}</div>
      <nz-row [nzGutter]="16"  class="top10" style="align-items: center;">
        <nz-col [nzSpan]="6">ETA</nz-col>
        <nz-col [nzSpan]="18">
          <nz-date-picker [nzShowTime]="{ nzFormat: 'HH:mm' }" nzFormat="yyyy-MM-dd HH:mm" [(ngModel)]="tmpETA" (ngModelChange)="validateTimeInput(key)" nzShowNow="false" style="width: 100%;"></nz-date-picker>
        </nz-col>
      </nz-row>
      <nz-row [nzGutter]="16" class="top10" style="align-items: center;">
        <nz-col [nzSpan]="6">Reason</nz-col>
        <nz-col [nzSpan]="18">
          <textarea nz-input placeholder="E.g Driver is too far from PU" [(ngModel)]="tmpReason" style="width: 100%;"
            [nzAutosize]="{ minRows: 1, maxRows: 3}"
          ></textarea>
        </nz-col>
      </nz-row>
    </div>
  </ng-template>
  <ng-template #tplMarkDriverNotPickup let-key>
    <div class="mark-driver-not-pickup">
      <div style="display: flex; justify-content: flex-end;">
        <button nz-button nzType="primary" nzTheme="outline" [disabled]="!canUpdateAnswer(key)" (click)="updateAnswer(key)">Confirm</button>
      </div>
      <nz-row [nzGutter]="16" class="top10" style="align-items: center;">
        <nz-col [nzSpan]="6">Reason</nz-col>
        <nz-col [nzSpan]="18">
          <textarea nz-input placeholder="E.g Driver is too far from PU" [(ngModel)]="tmpReason" style="width: 100%;"
            [nzAutosize]="{ minRows: 1, maxRows: 3}"
          ></textarea>
        </nz-col>
      </nz-row>
    </div>
  </ng-template>
  <ng-template #tplMarkContactFailed let-key>
    <div class="mark-contact-failed">
      <div class="flex flex-space-between" style="align-items: center;">
        <div style="font-weight: 500;">Enter Note</div>
        <button nz-button nzType="primary" nzTheme="outline" [disabled]="!canUpdateAnswer(key)" (click)="updateAnswer(key)">Confirm</button>
      </div>
      <div class="top15">
        <textarea nz-input placeholder="e.g I have called 3 times but got no response" 
          [(ngModel)]="tmpNoteContactFailed" style="width: 100%px;"
          [nzAutosize]="{ minRows: 1, maxRows: 3}"
        ></textarea>
      </div>
    </div>
  </ng-template>
</div>