import { BaseComponent } from "@abstract/BaseComponent";
import { NgIf } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Const } from "@const/Const";
import { environment } from "@env/environment";
import { UIHelper } from "@services/UIHelper";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzInputModule } from "ng-zorro-antd/input";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";


const CustomInputs = [
  NgIf,
  FormsModule,
  NzInputModule,
  NzToolTipModule,
  NzIconModule,
  NzButtonModule
]

@Component({
  selector: '[send-tracking-link-btn]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})

export class SendTrackingLinkBtn extends BaseComponent {
  @Input() routeData: any;
  @Output() sendTrackingLinkCompleted: EventEmitter<any> = new EventEmitter<any>();
  @Output() remindedDriver: EventEmitter<any> = new EventEmitter<any>();

  public driverInfo: any;
  public trackingLink: string = '';
  public message: string = '';
  public trackingLinkExample = "https://native-app.wearewarp.com/driver/95FF14YRHF8Y";
  public isSent: boolean = false;
  public onProgress: boolean = false;
  public isRefusedLiveTracking: boolean = false;

  ngOnChanges() {
    this.driverInfo = this.routeData?.driver;
    let driverName = this.getDriverName();
    this.message = `${driverName},  this is WARP, you have a route today. Please click the link to go to driver app then START route [${this.routeData?.code}]`
    this.trackingLink = this.getTrackinglink();
  }

  getDriverName() {
    if (this.driverInfo?.name) return this.driverInfo.name;
    return `${this.driverInfo?.firstName || ''} ${this.driverInfo?.lastName || ''}`.trim()
  }

  get canSend() {
    return this.trackingLink ? true : false;
  }

  getTrackinglink() {
    let token = this.routeData?.assignedDriver?.token || '';
    return `https://native-app.wearewarp.com/driver/${token}` || '';
  }
  
  onBtnSendTrackingLink() {
    let jobId = this.routeData?.id;
    let driverId = this.driverInfo?.id;
    if (!jobId || !driverId) {
      UIHelper.showErr("Invalid input");
      return;
    }
    this.onProgress = true;
    let fullMessage = `${this.message}\n${this.trackingLink}`;
    let formData = new FormData();
    const jsonData = {
      content: fullMessage,
      sendTypes: ['sms'],
      driverId: this.driverInfo.id,
    }
    formData.append('params', JSON.stringify(jsonData));
    let url = `${environment.backendUrl}/${Const.APIURI_CONVERSATIONS}?subjectId=${jobId}&subjectType=job&type=driver_message`;
    this.api.postFormData(url, formData).subscribe({
      next: (resp) => {
        UIHelper.showSuccess("Send tracking link successfully");
        this.onProgress = false;
        this.isSent = true;
        this.sendTrackingLinkCompleted.emit(fullMessage);
        this.remindedDriver.emit('send-tracking-link');
      },
      error: (e) => {
        this.onProgress = false;
        UIHelper.showErr(e);
      }
    })
  }

  onDriverRefuse() {
    let message = "Are you sure the Driver has refused to enable live-tracking?";
    UIHelper.confirm(message, () => {
      this.markDriverRefusedLiveTracking();
    }, {
      title: "Confirm Driver Refuse",
      okText: "Confirm",
      className: "confirm-driver-refused"
    })
  }

  markDriverRefusedLiveTracking() {
    UIHelper.showSuccess("Update successfully");
    this.isRefusedLiveTracking = true;
    this.remindedDriver.emit('mark-driver-refused');
  }
}