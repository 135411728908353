import { WorkQueueType } from "@wearewarp/types";
import { ApiService } from "@services/api.service";
import { Injectable } from "@angular/core";
import { CompleteTaskRouteConfirmationService } from "./completeTaskRouteConfirmation.service";
import { CompleteTaskETAConfirmationService } from "./completeTaskETAConfirmation.service";
import { TaskService } from "../task.service";
import { CompleteTaskOTPConfirmationService } from "./completeTaskOTPConfirmation.service";
/**
 * Dùng xử lý thêm khi complete task
 * Viết service riêng để complete cho từng loại task 
 */
@Injectable()
export class CompleteTaskServiceManager {
  constructor(protected api: ApiService, private taskService: TaskService) {
  }

  public getService(type: WorkQueueType) {
    switch (type) {
      case 'routeConfirmation':
        return new CompleteTaskRouteConfirmationService(this.api);
      case 'otpConfirmation':
        return new CompleteTaskOTPConfirmationService(this.api, this.taskService);
      case 'etaConfirmation':
        return new CompleteTaskETAConfirmationService(this.api, this.taskService);
    }
  }
}