import { Routes } from "@angular/router";
import { DispatcherDashboardComponent } from "./dashboard";
import { TaskQueueComponent } from "./task-queue";
import { PageError } from "@app/page-error/page-error";
import { ManageSpecificTaskComponent } from "./task-queue/manager-specific-task";
import { PermissionCode } from "@const/PermissionCode";


export const DASHBOARD_ROUTES: Routes = [
  {
    path: '', component: DispatcherDashboardComponent, data: { title: 'Dashboard - WARP Work' },
    children: [
      { path: 'task-queue', component: TaskQueueComponent, data: { title: 'Task - WARP Work'}},
      { path: 'task-queue/:id', component: ManageSpecificTaskComponent, data: {title: 'Task - WARP Work', permissions: [PermissionCode.work_task.get_specific_task]}},
      { path: '**', component: PageError }
    ]
  }
]