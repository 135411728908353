<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>

<form *ngIf="!isLoading" [formGroup]="formInput" nz-form class="form-container">
  <div class="flex">
    <div class="bottom20" style="font-size: 20px;">{{assignDriverLabel}}</div>
  </div>

  <div *ngIf="readOnly" class="bottom15">
    This load is started. You can't change driver!
  </div>

  <div class="full-w">
    <div class="bottom15">Primary Driver <span class="label-mark-required"></span></div>
    <ng-container>
      <nz-form-item *ngFor="let key of ['driverId']">
        <nz-form-control>
          <nz-select style="width: 100%;" 
            [nzLoading]="isLoading"
            [formControlName]="key"
            nzShowSearch nzAllowClear nzPlaceHolder="Select"
            [nzDropdownRender]="createDriver"
            [nzDisabled]="readOnly"
          >
            <nz-option *ngFor="let driver of listDrivers" 
              [nzLabel]="getDriverNameAndPhoneLabel(driver)" 
              [nzValue]="driver.id">
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </ng-container>
  </div>
  <ng-template #createDriver>
    <a (click)="onBtnCreateDriver()" style="text-decoration: underline; padding: 8px;"><i nz-icon nzType="plus" nzTheme="outline"></i> Add New Driver</a>
  </ng-template>

  <div class="group-location top20">
    <div class="form-label">Driver Location</div>
    <form nz-form *ngFor="let gKey of ['location']" [formGroupName]="gKey">
      <div class="flex gap12">
        <div style="flex: 1">
          <nz-form-item>
            <nz-form-control>
              <input id="city" 
                [formControlName]="'city'" 
                nz-input #driverLocation
                [nzAutocomplete]="autoComplete1"
                placeholder="Enter City" 
                (input)="onInputChanged($event, 'city')" 
                (keypress)="onInputKeyPress($event, 'city')"
              >
              <nz-autocomplete #autoComplete1>
                <nz-auto-option *ngFor="let item of listAddressAutoComplete['city']; let i = index" 
                  [nzValue]="getCityItem(item)" (selectionChange)="onLocationSelected($event, item, 'primary')">
                  {{item}}
                </nz-auto-option>
              </nz-autocomplete>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div style="flex: 1">
          <nz-form-item>
            <nz-form-control>
              <nz-select 
                nzBackdrop="true" style="width: 100%;" 
                nzShowSearch nzAllowClear 
                nzPlaceHolder="Select State" 
                [formControlName]="'state'"
              >
                <nz-option-group *ngFor="let group of allStates" [nzLabel]="group.label">
                  <nz-option *ngFor="let state of group.items"
                  [nzLabel]="getStateDesc(state)" [nzValue]="state.code">
                  </nz-option>
                </nz-option-group>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </form>
  </div>
  <ng-container *ngIf="isRequiredTeamDriver">
    <div class="divider"></div>
    <form nz-form *ngFor="let groupKey of ['secondaryDriver']" [formGroupName]="groupKey">
      <div class="full-w">
        <div class="bottom15">Secondary Driver <span class="label-mark-required"></span></div>
        <ng-container>
          <nz-form-item *ngFor="let key of ['driverId']">
            <nz-form-control>
              <nz-select style="width: 100%;" 
                [nzLoading]="isLoading"
                [formControlName]="key"
                nzShowSearch nzAllowClear nzPlaceHolder="Select"
                [nzDropdownRender]="createDriverSecondary"
                [nzDisabled]="readOnly"
              >
                <nz-option *ngFor="let driver of listDrivers" 
                  [nzLabel]="getDriverNameAndPhoneLabel(driver)" 
                  [nzValue]="driver.id">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </ng-container>
      </div>
      <ng-template #createDriverSecondary>
        <a (click)="onBtnCreateDriverSecondary()" style="text-decoration: underline; padding: 8px;"><i nz-icon nzType="plus" nzTheme="outline"></i> Add New Driver</a>
      </ng-template>
      <div class="group-location top20">
        <div class="form-label-v2">Driver Location</div>
        <form nz-form *ngFor="let gKey of ['location']" [formGroupName]="gKey">
          <div class="flex gap12">
            <div class="flex1">
              <nz-form-item>
                <nz-form-control>
                  <input id="city2" 
                    [formControlName]="'city'" 
                    nz-input #driverLocation2
                    [nzAutocomplete]="autoComplete2"
                    placeholder="Enter City" 
                    (input)="onInputChanged($event, 'city')" 
                    (keypress)="onInputKeyPress($event, 'city')"
                  >
                  <nz-autocomplete #autoComplete2>
                    <nz-auto-option *ngFor="let item of listAddressAutoComplete['city2']; let i = index" 
                      [nzValue]="getCityItem(item)" (selectionChange)="onLocationSelected($event, item, 'secondary')">
                      {{item}}
                    </nz-auto-option>
                  </nz-autocomplete>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="flex1">
              <nz-form-item>
                <nz-form-control>
                  <nz-select 
                    nzBackdrop="true" style="width: 100%;" 
                    nzShowSearch nzAllowClear 
                    nzPlaceHolder="Select State" 
                    [formControlName]="'state'"
                    [nzDisabled]="readOnly"
                  >
                    <nz-option-group *ngFor="let group of allStates" [nzLabel]="group.label">
                      <nz-option *ngFor="let state of group.items"
                      [nzLabel]="getStateDesc(state)" [nzValue]="state.code">
                      </nz-option>
                    </nz-option-group>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </form>
      </div>
    </form>
  </ng-container>
</form>

<div class="footer">
  <button nz-button (click)="onBtnSave()" nzShape="round" [nzLoading]="onProgress" [disabled]="!canClickOK" class="btn btn-submit">{{ btnSubmitLabel }}</button>
  <button nz-button (click)="closeDialog()" nzShape="round" class="btn">{{ btnCancelLabel }}</button>
</div>