import { BaseCompleteTaskService } from "./base.service";
import { AnswersTaskRouteConfirmation } from "@wearewarp/types/data-model/types/work-queue";
import { Const } from "@const/Const";
import { Log } from "@services/log";
import { UIHelper } from "@services/UIHelper";

export class CompleteTaskRouteConfirmationService extends BaseCompleteTaskService {
  
  public async completeTask(data) {
    const { jobId, answer } = data;
    if (! jobId || ! answer) {
      return UIHelper.showErr('[Complete service] Missing jobId or answer');
    }
    let type = <AnswersTaskRouteConfirmation> answer.type;
    switch (type) {
      case 'contactFailed':
        if (answer?.data?.needRecovery) 
          return await this.requestCarrierRecovery(jobId);
        return;
      case 'waitingForConfirmation':
        if (answer?.data?.needRecovery)
          return await this.requestCarrierRecovery(jobId);
        return;
      case 'declinedRoute':
        return await this.carrierDeclinedRoute(jobId, answer?.data?.reason);
      case 'keepRoute':
        return;
    }
  }

  private async requestCarrierRecovery(jobId) {
    let url = `${Const.APIURI_WORK_QUEUE}/request-recovery`;
    this.api.POST(url, {jobId}).subscribe({
      next: () => {
        Log.d('Request carrier recovery successfully');
      },
      error: (e) => { Log.e(e); }
    });
  }

  private async carrierDeclinedRoute(jobId, reason?: string) {
    let url = `${Const.APIURI_WORK_QUEUE}/carrier-declined-route`;
    this.api.POST(url, {jobId, reason}).subscribe({
      next: () => {
        Log.d(`Update carrier declined route successfully for job ${jobId}`);
      },
      error: (e) => { Log.e(e); }
    });
  }
}