import { NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from "@angular/common";
import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { Utils } from "@services/utils";
import { TaskComponentsVisibiltyService } from "../../services/task-components-visibility.service";
import { RouteService } from "../../services/route.service";
import RouteEntity from "../../entity/RouteEntity";
import { Subscription } from "rxjs";
import StopEntity from "../../entity/StopEntity";
import { DateUtil } from "@services/date-util";
import { NzStepsModule } from "ng-zorro-antd/steps";
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { TaskService } from "../../services/task.service";

interface StopDisplayInfo {
  type?: string,
  locationName?: string,
  index: number,
  address?: string,
  refNums?: any[],
  appointment?: string,
  schedule?: string,
  eta?: string,
  ata?: string,
  instructions?: string,
  shipments: any[],
}

const CustomInputs = [
  NgIf, NgFor, 
  NgSwitch, NgSwitchCase, NgSwitchDefault,
  NzGridModule,
  NzButtonModule,
  NzIconModule,
  NzToolTipModule,
  NzStepsModule,
  NzCollapseModule,
  NzTagModule
]
@Component({
  selector: '[task-queue-route-detail]',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})

export class RouteDetail {
  private _model: any;
  set model(value) {
    this._model = value;
  }
  get model() {
    return this._model;
  }
  private subscription: Subscription = new Subscription();
  private route: RouteEntity;
  private stops: StopEntity[];
  public displayInfos;
  public shipmentItems;
  public generalItemArr = ['clients', 'equipment', 'items'];
  public currentStopIndex = 0;

  constructor(
    private taskService: TaskService,
    private routeService: RouteService,
    private visibilityService: TaskComponentsVisibiltyService
  ) {

  }

  ngOnInit() {
    this.subscription.add(this.routeService.routeData$.subscribe(() => {
      this.route = this.routeService.getRoute();
      this.model = this.route.getData();
      this.stops = this.route.getStops() || [];
      this.shipmentItems = this.route.getShipmentItems();
      this.buildDisplayInfo();
    }))
    this.currentStopIndex = this.taskService?.getCurrentStopEntity()?.getIndex() || 0;
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  buildDisplayInfo() {
    this.displayInfos = {
      code: this.model?.code || '',
      routeStatus: Const.getJobStatusText(this.model?.status),
      origin: this.getFirstPickup(),
      destination: this.getLastDropoff(),
      clients: this.buildDisplayClients(),
      equipment: this.getEquipments(),
      items: this.buildDisplayItems(),
      stops: this.buildDisplayStops()
    }
  }

  onCollapseRouteDetail() {
    this.visibilityService.changeRouteDetailVisibility();
  }

  getListClientsName() {
    return this.model?.clients?.map(it => it.name) || [];
  }

  getClientsTooltipTitle() {
    let clients = this.getListClientsName();
    return clients.join(', ');
  }

  buildDisplayClients() {
    let clients = this.getListClientsName();
    if (clients.length == 0) return 'N/A';
    if (clients.length == 1) return clients[0];
    let numOfHidden = clients.length - 2;
    let text = `${clients[0]} - ${clients[1]}`;
    if (numOfHidden) text = `${text} (+${numOfHidden})`;
    return text;
  }

  buildDisplayItems() {
    let items = this.shipmentItems || [];
    let totalWeight = 0;
    let totalPallet = 0;
    for (let item of items) {
      // Hiện tại chỉ tính theo lbs
      if (item?.weightUnit === 'lbs') {
        let qty = Utils.toNumber(item.qty, 0);
        let weightPerUnit = Utils.toNumber(item.weightPerUnit, 0)
        totalWeight += qty * weightPerUnit;
        totalPallet += qty;
      }
    }
    return `${totalWeight} lbs, ${totalPallet} ${totalPallet > 1 ? 'pallets' : 'pallet'}`;
  }

  getDisplayLocations() {
    let origin = this.displayInfos?.origin || '';
    let destination = this.displayInfos?.destination || '';
    return `${origin} - ${destination}`;
  }

  getGeneralItemLabel(key) {
    switch (key) {
      case 'clients': return 'Customer';
      case 'equipment': return 'Equipment';
      case 'items': return 'Items';
      default: return ''; 
    }
  }

  getGeneralItemValue(key) {
    if (!this.generalItemArr.includes(key)) return '';
    return this.displayInfos?.[key] || 'N/A';
  }

  getEquipments() {
    let vehicle = this.model?.requiredVehicle;
    if (!vehicle) return 'N/A';
    if (vehicle.options?.length) {
      return `${vehicle.name} /w ${vehicle.options.map(Utils.capitalize).join(", ")}`;
    }
    return vehicle.name;
  }

  getFirstPickup() {
    let stops = this.model?.stops || [];
    return this.getDisplayLocationCity(stops[0]);
  }

  getLastDropoff() {
    let stops = this.model?.stops || [];
    return this.getDisplayLocationCity(stops[stops.length - 1]);
  }

  getDisplayLocationCity(stop) {
    if (!stop) return '';
    return stop?.info?.addr?.city || '';
  }

  buildDisplayStops(): StopDisplayInfo[]  {
    return this.stops.map(it =>{
      let timezone = it.getTimeZoneStandard();
      let shipmentCodes = it.getShipments().map(it => it.getCode() || it.getDisplayWarpId());
      return {
        type: Const.getTaskTypeText(it.getType()),
        locationName: it.getLocationName(),
        index: it.getDisplayIndex(),
        address: it.getAddressText(),
        refNums: it.getRefNums(),
        schedule: this.getDisplayTimeWindow(it.getScheduleTime(), timezone),    // timwindow
        appointment: this.getDisplayTimeWindow(it.getAppointment(), timezone),    // timewindow
        eta: this.getDisplayTimeWindow(it.getETA(), timezone),          // eta: timewindow
        ata: this.getDisplayTime(it.getArrivedTime(), timezone),        // ata: stringIso
        instructions: it.getDeliveryInfo()?.instructions,
        shipments: shipmentCodes || []
      }
    })
  }

  private getDisplayTimeWindow(timeWindow, timezone) {
    if (!timeWindow) return '';
    let shortTz = DateUtil.timezoneStandardToUsShort(timezone);
    let windowStr = DateUtil.displayTimeWindow(timeWindow, {
      timezone: timezone,
      format: 'MMM DD, YYYY h:mm A',
      formatDateOnly: 'MMM DD, YYYY'
    })
    return `${windowStr} ${shortTz}`.trim();
  }

  // Tính theo timezone của stop hiện tại
  private getDisplayTime(time, timezone) {
    if (!time) return 'N/A';
    let shortTz = DateUtil.timezoneStandardToUsShort(timezone);
    let localTime =  DateUtil.displayLocalTime(time, timezone, 'MMM DD, YYYY h:mm A');
    return `${localTime} ${shortTz}`.trim();
  }

}