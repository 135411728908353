import { Component } from "@angular/core";
import { BaseTaskComponent } from "../BaseTaskComponent";
import { TaskService } from "../services/task.service";
import { RouteService } from "../services/route.service";
import { Const } from "@const/Const";
import { TaskOverview } from "../base/task-overview";
import { NzGridModule } from "ng-zorro-antd/grid";
import { ActionLogHistory, IByData, LogAction } from "@app/interfaces";
import StopEntity from "../entity/StopEntity";
import RouteEntity from "../entity/RouteEntity";
import { NzIconModule } from "ng-zorro-antd/icon";
import { LogHistoryComponent } from "../base/log-history";
import { NgClass, NgFor, NgIf } from "@angular/common";
import { CarrierAnswerForTaskRouteConfirmation } from "./components/carrier-answers";
import { RouteConfirmationAnswerData } from "@app/interfaces/answer-data";
import { DateUtil } from "@services/date-util";
import { GetWhenByService } from "../services/getWhenBy.service";
import { UIHelper } from "@services/UIHelper";
import { ContactCarrierComponent } from "../base/contact-carrier-box";
import { Log } from "@services/log";
import { DialogService } from "@app/dialogs/dialog.service";
import { ResendConfirmBookingDialog } from "./components/resend-confirm-booking-dialog";


const CustomInputs = [
  NgIf, NgFor, NgClass,
  NzGridModule,
  NzIconModule,

  TaskOverview,
  LogHistoryComponent,
  ContactCarrierComponent,
  CarrierAnswerForTaskRouteConfirmation,
  ResendConfirmBookingDialog
]

@Component({
  selector: 'task-route-confirmation',
  standalone: true,
  imports: [CustomInputs],
  providers: [GetWhenByService],
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})

export class TaskRouteConfirmation extends BaseTaskComponent {
  public logHistory: ActionLogHistory[] = [];
  public stopTimeZone;
  private route: RouteEntity;
  public routeData: any;
  public currentStop: StopEntity;
  public carrierInfo: any;
  public assignedCarrierId;
  public stopScheduleTime;
  public confirmItems = {
    loadTender: { label: 'Load Tender', status: false, confirmedByTxt: '', hidden: false },
    equipment: { label: 'Equipment', status: false, confirmedByTxt: '', hidden: false },
    assignedDriver: { label: 'Driver', status: false, confirmedByTxt: '', hidden: false },
  }
  public confirmItemsArr = Object.keys(this.confirmItems);
  public isLoading: boolean = false;
  private timerResendMail;
  public timeCountdown: number = 0;

  constructor(
    private taskService: TaskService,
    private routeService: RouteService,
    private getWhenByService: GetWhenByService
  ) {
    super();    
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscription.add(this.routeService.routeData$.subscribe(() => {
      this.loadData();
    }))
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    clearInterval(this.timerResendMail);
  }

  getRoute() {
    return this.route;
  }

  addTimerResendMail() {
    this.timeCountdown = 60;
    this.timerResendMail = setInterval(() => {
      this.timeCountdown -= 1;
      if (this.timeCountdown === 0) {
        clearInterval(this.timerResendMail);
      }
    }, 1000);
  }

  get taskName() {
    return Const.TrackingTaskLabel.routeConfirmation;
  }

  get isConfirmedLoadtender() {
    return this.confirmItems.loadTender.status;
  }

  get requireCarrierAcceptLoadtender() {
    return this.route.getData()?.settings?.isRequireCarrierAcceptLoadTender;
  }

  get statusConfirmationText() {
    let listItems = this.confirmItemsArr;
    if (!this.requireCarrierAcceptLoadtender) {
      listItems = listItems.filter(key => key !== 'loadTender');
    }
    let unconfirmItems = listItems.filter(key => !this.confirmItems[key].status);
    if (unconfirmItems.length) {
      return `${unconfirmItems.length}/${listItems.length} ${unconfirmItems.length > 1 ? 'items':'item'} not yet confirmed`;
    } else {
      return `All items have been confirmed`;
    }
  }

  get remindCarrierLabel() {
    return "Call to remind carrier to complete the confirmation";
  }

  get needAutoCompleteTask() {
    // Nếu không có carrier hoặc carrier đã decline route thì complete task luôn
    let route = this.routeService.getRoute();
    let assignedCarrier = route?.getCarrierId();
    if (!assignedCarrier) return true;
    let status = route?.getStatus();
    if (status !== Const.JobStatus.created) return true;
    return false;
  }

  loadData() {
    if (this.needAutoCompleteTask) {
      this.onAutoCompleteTask();
      return;
    }
    this.route = this.routeService.getRoute();
    this.routeData = this.routeService.getRoute()?.getData();
    this.currentStop = this.taskService.getCurrentStopEntity();
    this.stopTimeZone = this.currentStop?.getTimeZoneStandard();
    this.logHistory = this.taskService.getLogHistory();
    this.carrierInfo = this.routeData?.carrier;
    this.assignedCarrierId = this.route.getCarrierId();
    this.stopScheduleTime = this.currentStop?.getScheduleTime();
    this.checkConfirmItemsStatus();
  }

  async onAutoCompleteTask() {
    let route = this.routeService.getRoute();
    let routeStatus = route.getStatus();
    let content;
    let title;
    let reason;
    if (routeStatus === Const.JobStatus.created) {
      title = 'No Carrier for this route';
      content = 'This route has no carrier assigned.';
      reason = '';
      let historyChangeCarrier = route.getData()?.historyChangeCarrier || [];
      if (historyChangeCarrier.length) {
        let lastChange = historyChangeCarrier[historyChangeCarrier.length - 1];
        if (lastChange.code === 'carrier_declined_booking') {
          title = 'Carrier declined this route';
          reason = lastChange.reason;
          content = `Carrier declined this route "due to ${reason}". System has notified your team to find recovery on Slack channel 'need-recovery'.<br/><br/>Please proceed to Next task.`;
          reason = lastChange.reason;
        }
      }
    } else {
      if (routeStatus === Const.JobStatus.completed) {
        title = 'Route has been completed';
        content = 'This route has been completed.';
      }
      else if (routeStatus === Const.JobStatus.inProgress) {
        title = 'Route is in progress';
        content = 'This route is in progress.';
      }
      else if (routeStatus === Const.JobStatus.canceled) {
        title = 'Route has been canceled';
        content = 'This route has been canceled.'; 
      }
    }

    UIHelper.confirm(content, () => {
      Log.d("Auto Complete Task");
      super.onAutoCompleteTask();
    }, {
      title: title,
      okText: 'Next Task',
      className: 'modal-complete-task',
      cancelDisabled: true
    })
  }

  async checkConfirmItemsStatus() {
    this.confirmItems.loadTender.status = this.route.getConfirmLoadtenderStatus() === 'accepted' ? true : false;
    this.confirmItems.equipment.status = this.route.getConfirmReadyStatus();
    this.confirmItems.assignedDriver.status = this.route.getDriverId() &&  this.route.getConfirmDriverStatus() ? true : false;
    if (!this.requireCarrierAcceptLoadtender) {
      this.confirmItems.loadTender.hidden = true;
    }
    let timezone = this.stopTimeZone;
    let collection;
    if (this.confirmItems.loadTender.status) {
      let confirmedLTInfo = this.route?.getAcceptedLoadtenderBy();
      let confirmedLTBy = await this.getWhenByData(confirmedLTInfo);
      collection = confirmedLTInfo?.collection;
      this.confirmItems.loadTender.confirmedByTxt = `by ${this.getWhenByService.getFullName(confirmedLTBy, collection)} at ${this.getWhenByService.getDisplayUpdatedTime(confirmedLTInfo?.when, timezone)}`;
    }
    if (this.confirmItems.equipment.status) {
      let confirmedReadyInfo = this.route?.getConfirmedReadyBy();
      let confirmedEquipmentBy = await this.getWhenByData(confirmedReadyInfo);
      collection = confirmedReadyInfo?.collection;
      this.confirmItems.equipment.confirmedByTxt = `by ${this.getWhenByService.getFullName(confirmedEquipmentBy, collection)} at ${this.getWhenByService.getDisplayUpdatedTime(confirmedReadyInfo?.when, timezone)}`;
    }
    if (this.confirmItems.assignedDriver.status) {
      let confirmedDriverInfo = this.route?.getConfirmedDriverReadyBy();
      let confirmedDriverBy = await this.getWhenByData(confirmedDriverInfo);
      collection = confirmedDriverInfo?.collection;
      this.confirmItems.assignedDriver.confirmedByTxt = `by ${this.getWhenByService.getFullName(confirmedDriverBy, collection)} at ${this.getWhenByService.getDisplayUpdatedTime(confirmedDriverInfo?.when, timezone)}`;
    }
  }

  getStopScheduleTime() {
    let timeWindow = this.stopScheduleTime;
    return this.getDisplayTimeWindow(timeWindow);
  }

  getItemLabel(key) {
    let confirmedByTxt = this.confirmItems[key].confirmedByTxt;
    switch(key) {
      case 'loadTender':
        return this.isConfirmedItem(key) ? `Load Tender accepted${confirmedByTxt ? ' - ' + confirmedByTxt : ''}` : 'Load Tender';
      case 'equipment':
        return this.isConfirmedItem(key) ? `Equipment ready${confirmedByTxt ? ' - ' + confirmedByTxt : ''}` : 'Equipment';
      case 'assignedDriver':
        return this.isConfirmedItem(key) ? `Driver ready${confirmedByTxt ? ' - ' + confirmedByTxt : ''}` : 'Driver';
    }
    return this.confirmItems[key]?.label;
  }

  isConfirmedItem(key) {
    return this.confirmItems[key]?.status;
  }

  getRemindCarrierDescription() {
    let pickDropCity = this.route?.getPickDropCity();
    if (this.isConfirmedLoadtender) {
      return `"I noticed that you haven't completed the confirmation for the route from ${pickDropCity.pickCity} to ${pickDropCity.dropCity}, with a pickup scheduled for <b>${this.getStopScheduleTime()}</b>. Would you like to keep this route?"`;
    } else {
      return `"I noticed that you haven't completed the confirmation for the route from ${pickDropCity.pickCity} to ${pickDropCity.dropCity}, with a pickup scheduled for <b>${this.getStopScheduleTime()}</b>. Could you please open the confirmation email and confirm there?"`;
    }
  }
  
  async refreshRouteData() {
    this.isLoading = true;
    await this.routeService.refresh();
    this.isLoading = false;
  }

  onResponseChange(resp: RouteConfirmationAnswerData) {
    this.taskService.updateAnswerData(resp);
    this.onStepAnswerCompleted();
  }

  private getDisplayTimeWindow(timeWindow) {
    if (!timeWindow) return 'N/A';
    let timezone = this.stopTimeZone;
    let shortTz = DateUtil.timezoneStandardToUsShort(timezone);
    let windowStr = DateUtil.displayTimeWindow(timeWindow, {
      timezone: timezone,
      format: 'MMM DD, YYYY h:mm A',
      formatDateOnly: 'MMM DD, YYYY'
    })
    return `${windowStr} ${shortTz}`.trim();
  }

  async getWhenByData(data) {
    if (!data) return;
    let params: IByData = {
      collection: data?.collection || 'users',
      id: data?.byId,
      warpId: data?.by
    }
    return this.getWhenByService.getWhenByData(params);
  }

  public isSendMailLoading = false;
  onBtnResendBookingEmail() {
    let jobId = this.route.getId();
    if (!jobId) return UIHelper.showErr('Load id is required!');
    let pickDropCity = this.route?.getPickDropCity();
    const subject = `[Action required] ${this.route?.getCode()} Booking Confirmation - ${pickDropCity.pickCity} - ${pickDropCity.dropCity}`;
    let routeData: any = this.route.getData();
    const confirmLink = routeData?.automationTrackingUrl;
    DialogService.openFormDialog(ResendConfirmBookingDialog, {
      nzTitle: 'Resend Booking Confirmation email',
      nzData: {
        jobId,
        subject,
        confirmLink,
        emailContact: this.carrierInfo.basicInfo?.contacts?.[0]?.email,
        carrierName: this.carrierInfo.basicInfo?.contacts?.[0]?.firstName,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.onSendConfirmBookingEmailSuccess();
        }
      },
      nzClosable: true,
      nzMaskClosable: true,
      nzWidth: '510px',
    })
  }

  async onSendConfirmBookingEmailSuccess() {
    this.addTimerResendMail();
    // save log
    let actions: LogAction[] = ['sendConfirmBookingEmail']
    await this.taskService.saveLogActions(actions);
    this.logHistory = this.taskService.getLogHistory();
  }

  shouldShowItem(key) {
    return !this.confirmItems[key].hidden;
  }
}