<div *ngIf="isHasLogHistory">
  {{ getLatestLog() }}
  <span class="btn-log-history"
    nz-tooltip 
    [nzTooltipTrigger]="'click'"
    [nzTooltipTitle]="tplLogHistory"
    [nzTooltipPlacement]="'leftTop'"
    [nzTooltipColor]="'white'"
    [(nzTooltipVisible)]="showLogHistoryDialog"
    nzTooltipOverlayClassName="tooltip-log-history"
  >Log History</span>

  <ng-template #tplLogHistory>
    <div>
      <div class="flex flex-space-between">
        <div><span style="font-weight: 600;">Log History</span></div>
        <div><i nz-icon nzType="close" nzTheme="outline" (click)="onBtnCloseLogHistory()" style="cursor: pointer;"></i></div>
      </div>
      <div class="separator h top10 bottom10"></div>

      <div class="content-box" style="max-height: 300px; overflow-y: auto;">
        <div *ngFor="let item of displayHistoryInfos; let i = index">
          <div *ngIf="i > 0" class="separator h top10 bottom10"></div>
          <div class="log-item">
            <div class="flex flex-space-between">
              <div style="font-weight: 500;">{{ item.by?.name || 'N/A' }}</div>
              <div style="color:#d4d4d4">{{ item.by?.longAgo || 'N/A' }}</div>  
            </div>
            <div class="top10"><span style="white-space: pre-line;">{{item.desc || 'N/A'}}</span></div>
          </div>
        </div>  
      </div>
    </div>
  </ng-template>
</div>