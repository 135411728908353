import { AttachedFile } from "@wearewarp/types/data-model";
import { AWSUtil } from "./aws-util";
import { ApiService } from "./api.service";

export class AttachedFileUtil {

  public static attachedFileUrl(model: AttachedFile, usePresigned = false): string {
    if (!model) {
      return '';
    }
    if (model.s3Key) {
      if(usePresigned){
        return AWSUtil.getSignedUrlS3Object(model.s3Key, model.s3Bucket);
      }
      return AWSUtil.getUrlS3Object(model.s3Key, model.s3Bucket);
    }
    return `${ApiService.getImageUrl(model.url)}?name=${encodeURIComponent(model.name)}`;
  }
}