import { Directive, Inject } from "@angular/core";
import { BaseFormItem } from "@app/base-components/form-item";
import { NZ_MODAL_DATA, NzModalRef } from "ng-zorro-antd/modal";


@Directive()
export abstract class BaseFormDialog extends BaseFormItem {
  isDialog: boolean = false;
  getModalRef: () => NzModalRef = () => null;
  createSuccess: (resp) => void = () => {};
  updateSuccess: (resp) => void = () => {};
  onSave: (data) => void = null;
  closeOnSuccess: boolean = false;

  constructor(@Inject(NZ_MODAL_DATA) data: any) {
    super();
    this.isDialog = data.isDialog;
    this.getModalRef = data.getModalRef;
    this.createSuccess = data.createSuccess ?? this.createSuccess;
    this.updateSuccess = data.updateSuccess ?? this.updateSuccess;
    this.onSave = data.onSave;
    this.closeOnSuccess = data.closeOnSuccess || false;
  }

  get isCreateNew(): boolean {
    return !this.model || !this.model._id;
  }
  get isEditOrCreate(): boolean {
    return this.isCreateNew || this.isEditing;
  }
  get id() {
    if (!this.model) return '';
    return this.model._id;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  protected get nzModal(): NzModalRef {
    return this.getModalRef();
  }
  
  public closeDialog() {
    this.nzModal?.destroy();
  }
  
  protected onCreateSuccess(resp) {
    this.createSuccess(resp);
    if (this.closeOnSuccess) {
      this.closeDialog();
    }
  }
  
  protected onUpdateSuccess(resp) {
    this.updateSuccess(resp);
    if (this.closeOnSuccess) {
      this.closeDialog();
    }
  }

  onBtnSave(): void {
    if (this.onSave) {
      this.onSave(this.getFormData_JSON(true));
      if (this.closeOnSuccess) {
        this.closeDialog();
      }
    }
  }
}