import { Const } from "@const/Const";
import { DeliveryInfo } from "@wearewarp/types/data-model";
import { TaskType } from "@wearewarp/types";

export class BizUtil {
  static getDeliveryInfo(type: string, order: {deliveryInfos: Array<{id?: any, type?: string}>}, deliveryId = undefined): DeliveryInfo {
    let info;
    if (deliveryId) {
      info = (order?.deliveryInfos ?? []).filter(it => it.id == deliveryId && it.type == type)[0];
    } else {
      info = (order?.deliveryInfos ?? []).filter(it => it.type == type)[0];
    }
    return info;
  }

  static getPickInfo(order: {deliveryInfos: Array<{id?: any, type?: TaskType}>}, deliveryId = undefined): DeliveryInfo {
    return BizUtil.getDeliveryInfo(Const.RouteTaskType.PICKUP, order, deliveryId);
  }

  static getDropInfo(order: {deliveryInfos: Array<{id?: any, type?: TaskType}>}, deliveryId = undefined): DeliveryInfo {
    return BizUtil.getDeliveryInfo(Const.RouteTaskType.DROPOFF, order, deliveryId);
  }
}
