<form class="form-detail" [formGroup]="formInput" nz-form>
    <div class="flex">
      <div class="f20 bottom20 flex1">Add Driver</div>
    </div>
    <form nz-form *ngFor="let groupKey of ['driverProfile']" [formGroupName]="groupKey">
        <div class="full-w flex gap10">
            <ng-container *ngFor="let key of ['firstName', 'lastName']">
                <div class="fw500 flex1">
                    {{getLabel(fullKey(groupKey, key))}}
                    <span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span>
                    <nz-form-item>
                        <nz-form-control>
                            <input nz-input [formControlName]="key"
                            [type]="getInputType(fullKey(groupKey, key))"
                            [placeholder]="getPlaceHolder(fullKey(groupKey, key))"
                            (input)="onInputChanged($event, key)" 
                            (keypress)="onInputKeyPress($event, key)">
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </ng-container>
        </div>
        <ng-container *ngFor="let key of ['phone']">
            <div class="fw500">
                {{getLabel(fullKey(groupKey, key))}}
                <span *ngIf="isRequired(fullKey(groupKey, key))" class="label-mark-required"></span>
                <nz-form-item>
                    <nz-form-control>
                        <input nz-input [formControlName]="key"
                        [type]="getInputType(fullKey(groupKey, key))"
                        [placeholder]="getPlaceHolder(fullKey(groupKey, key))"
                        (input)="onInputChanged($event, key)" 
                        (keypress)="onInputKeyPress($event, key)">
                    </nz-form-control>
                </nz-form-item>
                <div class="description">Driver can register with the above number</div>
            </div>
        </ng-container>
    </form>
</form>
<div class="footer">
    <button nz-button (click)="onBtnSave()" nzShape="round" [nzLoading]="onProgress" [disabled]="!canClickOK" class="btn btn-submit">{{ btnSubmitLabel }}</button>
  <button nz-button (click)="closeDialog()" nzShape="round" class="btn">{{ btnCancelLabel }}</button>
</div>
