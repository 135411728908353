import { Component } from '@angular/core';

@Component({
  selector: 'page-error',
  standalone: true,
  template: `
  <div style="width:800px; margin:0 auto; text-align: center; font-size: 2em; padding-top: 50px;">
  400 Bad Request <br/> Ops... something went wrong with this page {{urlPath}}
  <div>
  `,
})
export class PageError {
  constructor() {
  }

  get urlPath() {
    return window.location.pathname;
  }
}