import { Injectable } from "@angular/core";
import { IByData } from "@app/interfaces";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { DateUtil } from "@services/date-util";
import { lastValueFrom } from "rxjs";

@Injectable()
export class GetWhenByService {
  constructor(private api: ApiService) {
  }
  
  public async getWhenByData(params: IByData) {
    let url = `${Const.APIURI_USERS}/get-when-by-info`;
    const resp = await lastValueFrom(this.api.POST(url, params));
    return resp?.data;
  }

  public getFullName(data, collection = 'users') {
    if (!data) return '';
    if (collection === 'carriers') return data.basicInfo?.name;
    if (data.fullName) return data.fullName;
    return [data.firstName || '', data.lastName || ''].join(' ').trim();
  }

  public getDisplayUpdatedTime(time: string, timezone?: string) {
    if (!time) return '';
    timezone = timezone || 'America/Los_Angeles';
    let shortTz = DateUtil.timezoneStandardToUsShort(timezone);
    let localTime = DateUtil.displayLocalTime(time, timezone, 'MM/DD/YYYY, h:mm A');
    return `${localTime} ${shortTz}`.trim();
  }
}