<div class="route-detail-container">
  <div class="flex flex-space-between header-box">
    <div class="flex">
      <div class="route-code">Route {{ displayInfos.code }}</div>
      <div class="route-status">{{ displayInfos.routeStatus }}</div>
    </div>
    <div>
      <button nz-button nzType="text" class="base-collapse-btn" (click)="onCollapseRouteDetail()"><i class="fa fa-fw fa-chevron-left"></i></button>
    </div>
  </div>
  <div class="content-box">
    <div>{{ getDisplayLocations() }}</div>
    <div class="top20">
      <div nz-row *ngFor="let key of generalItemArr" [nzGutter]="[16, 24]">
        <div nz-col [nzSpan]="7"><span class="label-text">{{ getGeneralItemLabel(key) }}</span></div>
        <div nz-col [nzSpan]="17">
          <ng-container [ngSwitch]="key">
            <ng-container *ngSwitchCase="'clients'">
              <span nz-tooltip [nzTooltipTitle]="getClientsTooltipTitle()">{{ getGeneralItemValue(key) }}</span>
            </ng-container>

            <ng-container *ngSwitchDefault>
              <span>{{ getGeneralItemValue(key) }}</span>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <!-- Display stops -->
    <div class="stops-info top15">
      <nz-steps [nzDirection]="'vertical'" [nzSize]="'small'" [nzCurrent]="currentStopIndex" [nzProgressDot]="true">
        <nz-step *ngFor="let item of displayInfos.stops || []; let i = index" [nzDescription]="locationStep">
          <ng-template #locationStep>
            <nz-collapse [nzBordered]="false">
              <nz-collapse-panel [nzHeader]="collapseHeader" [nzShowArrow]="false" [nzActive]="i === currentStopIndex">
                <ng-template #collapseHeader>
                  <div class="location-title-collapse">
                    <span class="bold-text" style="font-size: 15px;">{{ item.locationName || item.type }}</span>
                    <span class="left15 top5">Stop {{ item.index || 0 }}</span>
                  </div>
                  <div>{{ item.address }}</div>
                </ng-template>

                <div class="ref-nums">
                  <div class="bold-text label-text">Reference No.</div>
                  <div *ngIf="!item.refNums || item.refNums.length == 0" class="label-text top5">None</div>
                  <div *ngIf="item.refNums.length > 0" class="flex top5" style="flex-wrap: wrap;">
                    <div *ngFor="let ref of item.refNums"><nz-tag [nzMode]="'default'">{{ ref }}</nz-tag></div>
                  </div>
                </div>

                <div *ngIf="item.appointment" class="appointment top15">
                  <div class="bold-text label-text">Appointment</div>
                  <div class="top5">{{ item.appointment }}</div>
                </div>

                <div class="eta top15">
                  <div class="bold-text label-text">ETA</div>
                  <div class="top5">{{ item.eta || '-' }}</div>
                </div>

                <div *ngIf="item.schedule" class="schedule top15">
                  <div class="bold-text label-text">Schedule</div>
                  <div class="top5">{{ item.schedule }}</div>
                </div>

                <div *ngIf="item.ata" class="ata top15">
                  <div class="bold-text label-text">Actual Time</div>
                  <div class="top5">{{ item.ata }}</div>
                </div>

                <div class="location-instruction top15">
                  <div class="bold-text label-text">Location Instruction</div>
                  <div class="top5">{{ item.instructions || '-' }}</div>
                </div>

                <div class="shipments top15">
                  <div class="bold-text label-text">Shipment ({{item.shipments.length}})</div>
                  <div class="flex top5"  style="flex-wrap: wrap;">
                    <div *ngFor="let shipment of item.shipments">
                      <nz-tag [nzColor]="'#e0e0e0'" style="color: #52525B; font-size: 13px;">{{ shipment }}</nz-tag>
                    </div>
                  </div>
                </div>
              </nz-collapse-panel>  
            </nz-collapse>
          </ng-template>
        </nz-step>
      </nz-steps>
    </div>
  </div>
</div>