import { CommonModule } from "@angular/common";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzIconModule } from "ng-zorro-antd/icon";
import { TaskOverview } from "../base/task-overview";
import { LogHistoryComponent } from "../base/log-history";
import { ContactDriverComponent } from "../base/contact-driver-box";
import { Component } from "@angular/core";
import { BaseTaskComponent } from "../BaseTaskComponent";
import { TaskService } from "../services/task.service";
import { RouteService } from "../services/route.service";
import { ActionLogHistory } from "@app/interfaces";
import RouteEntity from "../entity/RouteEntity";
import StopEntity from "../entity/StopEntity";
import { DriverLocationService } from "@app/work-dispatcher/map/services/driver-location";
import { LiveTrackingStatus } from "../base/live-tracking-status";
import { Const } from "@const/Const";
import { SendTrackingLinkBtn } from "../base/send-tracking-link-btn";
import { NzStepsModule } from "ng-zorro-antd/steps";
import { ETAConfirmationAnswerData } from "@app/interfaces/answer-data";
import { AnswersForTaskETAConfirmation } from "./components/driver-answers";
import { ContactCarrierComponent } from "../base/contact-carrier-box";
import { Shipment } from "@wearewarp/types/data-model";
import { DispatchRouteMap } from "@app/work-dispatcher/map/route-map";
import { DateUtil } from "@services/date-util";


const CustomInputs = [
  CommonModule,
  NzGridModule,
  NzIconModule,
  NzStepsModule,

  TaskOverview,
  LogHistoryComponent,
  ContactDriverComponent,
  LiveTrackingStatus,
  AnswersForTaskETAConfirmation,
  SendTrackingLinkBtn,
  ContactCarrierComponent,
  DispatchRouteMap
]

@Component({
  selector: 'task-eta-confirmation',
  standalone: true,
  imports: [CustomInputs],
  templateUrl: './index.html',
  styleUrls: ['./index.scss'],
})

export class TaskETAConfirmation extends BaseTaskComponent {
  public stepIndex: number = 0;
  public isEnableLiveTracking: boolean = false;
  public isRemindedDriver: boolean = false;
  public stopTimeZone;
  private route: RouteEntity;
  public routeData: any;
  public currentStop: StopEntity;
  public shipments: Shipment[] = [];
  public contactEntity: 'driver' | 'carrier' = 'driver';
  public carrierId: string;
  public driverInfo: any;
  public logHistory: ActionLogHistory[] = [];
  public stopScheduleTime;

  constructor(
    private taskService: TaskService,
    private routeService: RouteService,
    private driverLocationService: DriverLocationService) {
    super();    
  }

  ngOnInit() {
    super.ngOnInit();
    this.isEnableLiveTracking = this.routeService.getLiveTrackingStatus();
    this.loadData();
  }

  get taskName() {
    return Const.TrackingTaskLabel.etaConfirmation;
  }

  get shouldRemindDriver() {
    return this.driverInfo && !this.isEnableLiveTracking;
  }

  get shouldContactDriver() {
    return this.contactEntity === 'driver';
  }

  getRoute() {
    return this.route;
  }

  get needAutoCompleteTask() {
    // Nếu đã arrived thì auto complete task
    let currentStop = this.taskService.getCurrentStopEntity();
    let currentStopStatus = currentStop?.getStatus();
    if (![Const.RouteTaskStatus.created, Const.RouteTaskStatus.enroute].includes(currentStopStatus)) {
      return true;
    }
    return false;
  }

  loadData() {
    if (this.needAutoCompleteTask) {
      this.onAutoCompleteTask();
      return;
    }
    this.route = this.routeService.getRoute();
    this.routeData = this.routeService.getRoute()?.getData();
    this.currentStop = this.taskService.getCurrentStopEntity();
    this.stopTimeZone = this.currentStop?.getTimeZoneStandard();
    this.shipments = this.route.getShipments().map(shipment => shipment.getData());
    this.logHistory = this.taskService.getLogHistory();
    this.driverInfo = this.route?.getDriver();
    this.stopScheduleTime = this.currentStop?.getScheduleTime();
    this.carrierId = this.route?.getCarrierId();
    this.getContactInfo();
  }

  getContactInfo() {
    this.contactEntity = 'driver';
    let listLogActions = this.logHistory.map(log => [...(log.actions || [])]).flat() || [];
    for (let action of listLogActions) {
      // Nếu gọi cho driver trước đó thì gọi cho carrier
      if (action === 'markContactDriverFailed') {
        this.contactEntity = 'carrier';
        break;
      }
      else if (action === 'markContactCarrierFailed') break;
    }
    if (!this.driverInfo) this.contactEntity = 'carrier';
  }

  public isShowMap: boolean = false;
  changeMapVisiable() {
    this.isShowMap = !this.isShowMap;
    if(this.isShowMap) this.driverLocationService.setRouteId(this.routeData.id);
  }

  getTaskFollowingTitle() {
    let title = "Follow steps below to complete this task";
    return title;
  }

  getStepTitle(index) {
    switch (index) {
      case 1: 
        return `Call ${this.contactEntity} to Ask "Can you confirm you'll be on time for pickup at ${this.getStopScheduleTime()}?"`;
      case 2:
        return 'Remind Driver to turn on Live tracking "Can you please turn on live tracking so we can know your location."'
    }
  }

  onRemindDriverCompleted(value) {
    // Chỉ truyền value khi là action mới, để ghi lại log
    if(value && value === 'send-tracking-link') {
      let log: ActionLogHistory = {
        actions: ['sendTrackingLink'],
      }
      this.taskService.updateLogHistory(log);
    }
    else if (value && value === 'mark-driver-refused') {
      let log: ActionLogHistory = {
        actions: ['markDriverRefusedLiveTracking'],
      }
      this.taskService.updateLogHistory(log);
    }
    this.isRemindedDriver = true;
    this.onStepAnswerCompleted();
  }

  onResponseChange(resp: ETAConfirmationAnswerData) {
    this.taskService.updateAnswerData(resp);
    this.onStepAnswerCompleted();
  }

  getStopScheduleTime() {
    let timeWindow = this.stopScheduleTime;
    return this.getDisplayTimeWindow(timeWindow);
  }

  private getDisplayTimeWindow(timeWindow) {
    if (!timeWindow) return 'N/A';
    let timezone = this.stopTimeZone;
    let shortTz = DateUtil.timezoneStandardToUsShort(timezone);
    let windowStr = DateUtil.displayTimeWindow(timeWindow, {
      timezone: timezone,
      format: 'MM/DD/YYYY, h:mm A',
      formatDateOnly: 'MM/DD/YYYY'
    })
    return `${windowStr} ${shortTz}`.trim();
  }

}
